
import Settings from "../Setting.vue";
import Input from "@/core-ui/forms/components/Input.vue";
import Button from "@/core-ui/Button.vue";

import { useToast } from "vue-toastification";
import { ref } from "vue";
import settingStore, { SettingKeys } from "@/stores/setting-store";
import Tooltip from "@/core-ui/forms/components/Tooltip.vue";

export default {
    props: {
        setting: {
            type: Object,
        },
    },
    components: { Settings, Button, Input, Tooltip },
    setup() {

        const toast = useToast();

        const isValid = ref(true);
        const sweepConfig = ref(settingStore.state.settings.find((setting) => setting.key === SettingKeys.WandbHost));

        const state = ref({
            host: settingStore.state.kv[SettingKeys.WandbHost],
        });

        function isValidUrl(val: string): boolean {
            const regex =
                /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*)$/;
            return regex.test(val);
        }

        const submitHandler = async (e: SubmitEvent) => {
            e.preventDefault();
            isValid.value = state.value.host === "" ? true : isValidUrl(state.value.host);
            if (!isValid.value) return;

            await settingStore.save(SettingKeys.WandbHost, state.value.host, toast);
        };

        const handleInput = () => {
            isValid.value = true;
        };

        return {
            state,
            sweepConfig,
            isValid,
            submitHandler,
            handleInput,
        };
    },
};
