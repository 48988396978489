

import { useToast } from "vue-toastification";
import { defineComponent, ref } from "vue";
import Loading from "@/core-ui/Loading.vue";
import ProgressBar from "@/core-ui/ProgressBar.vue";
import settingsStore, { SettingKeys, SettingType } from "@/stores/setting-store";
import Setting from "@/components/settings/Setting.vue";
import Settings from "@/components/settings/Setting.vue";

export default defineComponent({
    components: {
        Setting,
        ProgressBar,
        Loading,
        Settings
    },
    props: {
        setting: Object,
    },

    setup() {

        const selectedCategory = ref(null as any);
        const toast = useToast();

        const self = {
            selectedCategory,
            getSettings() {
                return settingsStore.state.settings.filter((setting: SettingType) => setting.key === SettingKeys.InactivityMinuetsTillLogout || setting.key ===SettingKeys.ExcludeViewerInactivityLogout);
            },

            getValue(key: string) {
                return settingsStore.state.kv[key];
            },

            async save(key: string, value: any) {
                await settingsStore.save(key, value, toast)
            }
        };
        return self;
    },
});
