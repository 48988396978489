
import Settings from "../Setting.vue";
import Input from "@/core-ui/forms/components/Input.vue";
import tenantStore from "@/stores/tenantStore";
import Button from "@/core-ui/Button.vue";
import registryIntegrationService, {
    DEFAULT_CREDENTIAL_KIND,
    REGISTRY_SCOPE,
} from "@/services/registry-integration-service";
import { useToast } from "vue-toastification";
import { computed, ref, watch } from "vue";
import { Registry, RegistrySpec, AssetMeta } from "@/types/registry-types";
import settingStore, { SettingKeys } from "@/stores/setting-store";

const CREATED_PASSWORD_PLACEHOLDER = "********";
export default {
    props: {
        setting: {
            type: Object,
        },
    },
    components: { Settings, Button, Input },
    setup() {
        const toast = useToast();

        const state = ref({
            url: tenantStore.state.registrySpec.url,
            user: tenantStore.state.registrySpec.user,
            password: tenantStore.state.registryId ? CREATED_PASSWORD_PLACEHOLDER : "",
            registryId: tenantStore.state.registryId,
        });

        const formInvalid = computed(() => {
            return !state.value.url || !state.value.password || !state.value.user;
        });
        const registryId = computed(() => tenantStore.state.registryId);
        const isFormDisabled = computed(() => {
            return !!state.value.registryId;
        });

        watch(registryId, () => {
            // reset form on feature flag off
            if (!tenantStore.state.registryId) {
                state.value.url = "";
                state.value.user = "";
                state.value.password = "";
                state.value.registryId = "";
            }
        });

        const getCreatePayload = (): Registry => {
            const spec: RegistrySpec = {
                url: state.value.url,
                user: state.value.user,
                password: state.value.password,
                credentialKind: DEFAULT_CREDENTIAL_KIND,
            };
            const meta: AssetMeta = { name: "registry-name", scope: REGISTRY_SCOPE };
            return { meta, spec };
        };

        const submitHandler = async (e: SubmitEvent) => {
            e.preventDefault();
            try {
                const payload: Registry = getCreatePayload();
                registryIntegrationService.createRegistry(payload).then((response) => {
                    state.value.registryId = response.meta.id;
                    state.value.password = CREATED_PASSWORD_PLACEHOLDER;
                    tenantStore.state.registryId = response.meta.id;
                    tenantStore.state.registrySpec.url = response.spec.url;
                    tenantStore.state.registrySpec.user = response.spec.user;
                    //toggle on RegistryIntegration feature flag
                    settingStore.save(SettingKeys.RegistryIntegration, true);
                });

                toast.success(`Registry Integration Configured`);
            } catch (ex) {
                toast.error(`Invalid credentials or registry URL path`);
                return Promise.reject("Invalid credentials or registry URL path");
            }
        };

        return {
            registryId,
            isFormDisabled,
            state,
            formInvalid,
            submitHandler,
        };
    },
};
