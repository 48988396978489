
import Code from "@/core-ui/Code.vue";
import { TenantServerConfigInfo } from "@/stores/tenantStore";
import { PropType, ref, toRefs } from "vue";

export default {
    props: {
        serverConfig: Object as PropType<TenantServerConfigInfo>,
    },

    components: { Code },

    setup: (props: any) => {
        const { serverConfig } = toRefs(props);
        return {
            get serverConfigHtml() {
                // unfortonately we need to render the html as string since this is the only we to render the html for the <pre> tag
                // in a way that will be identical to the required config syntax. This is ugly but creates an accurate config
                // which is critical for config presentation.
                let serverConfigHtml = `
<pre class="yaml-code">
<span class="yaml-key">spec</span><span class="yaml-colon">:</span>
  <span class="yaml-key">containers</span><span class="yaml-colon">:</span>
  <span class="yaml-item">- </span><span class="yaml-key">command</span><span class="yaml-colon">:</span>
    <span class="yaml-value">...</span>`;

                serverConfigHtml += `
    <span class="yaml-item">- </span><span class="yaml-value">--oidc-client-id=${serverConfig.value["clientId"]}</span>
    <span class="yaml-item">- </span><span class="yaml-value">--oidc-issuer-url=${serverConfig.value["issuerUrl"]}</span>
    <span class="yaml-item">- </span><span class="yaml-value">--oidc-username-prefix=-</span>`;

                if (serverConfig.value["groupsClaim"]) {
                    serverConfigHtml += `
    <span class="yaml-item">- </span><span class="yaml-value">--oidc-groups-claim=${serverConfig.value["groupsClaim"]}</span>
    <span class="yaml-item">- </span><span class="yaml-value">--oidc-username-claim=email</span>`;
                }

                serverConfigHtml += `</pre>`;
                return serverConfigHtml;
            },
        };
    },
};
