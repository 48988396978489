import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-03904ad5"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Code = _resolveComponent("Code")!

  return (_openBlock(), _createBlock(_component_Code, null, {
    default: _withCtx(() => [
      _createElementVNode("div", { innerHTML: _ctx.clientConfigHtml }, null, 8, _hoisted_1)
    ]),
    _: 1
  }))
}