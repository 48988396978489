import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-713359a5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "protocolSelectorWrapper" }
const _hoisted_3 = ["checked", "disabled", "onChange"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.disabled)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, "Select the SSO protocol"))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.protocolMap, ([key, value]) => {
        return (_openBlock(), _createElementBlock("label", null, [
          _createElementVNode("input", {
            type: "radio",
            checked: _ctx.isChecked(value),
            disabled: _ctx.disabled,
            onChange: ($event: any) => (_ctx.changeProtocol(key)),
            aid: "radio-button"
          }, null, 40, _hoisted_3),
          _createTextVNode(" " + _toDisplayString(value), 1)
        ]))
      }), 256))
    ])
  ]))
}