import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Settings = _resolveComponent("Settings")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, null, {
    default: _withCtx(() => [
      _createVNode(_component_Settings, { customComponents: _ctx.customComponents }, null, 8, ["customComponents"])
    ]),
    _: 1
  }))
}