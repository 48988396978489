
import Code from "@/core-ui/Code.vue";
import { TenantClientConfigInfo } from "@/stores/tenantStore";
import { PropType, ref, toRefs } from "vue";

export default {
    props: {
        clientConfig: Object as PropType<TenantClientConfigInfo>,
    },

    components: { Code },

    setup: (props: any) => {
        const { clientConfig } = toRefs(props);

        return {
            get clientConfigHtml() {
                // unfortonately we need to render the html as string since this is the only we to render the html for the <pre> tag
                // in a way that will be identical to the required config syntax. This is ugly but creates an accurate config
                // which is critical for config presentation.
                let clientConfigHtml = `
<pre class="yaml-code">
<span class="yaml-item">- </span><span class="yaml-key">name</span><span class="yaml-colon">: </span><span class="yaml-value">runai-authenticated-user</span>
  <span class="yaml-key">user</span><span class="yaml-colon">: </span>
    <span class="yaml-key">auth-provider</span><span class="yaml-colon">: </span>
      <span class="yaml-key">config</span><span class="yaml-colon">: </span>`;

                if (clientConfig.value["airgapped"]) {
                    clientConfigHtml += `
        <span class="yaml-key">airgapped</span><span class="yaml-colon">: </span><span class="yaml-value">"true"</span>`;
                }

                clientConfigHtml += `
        <span class="yaml-key">auth-flow</span><span class="yaml-colon">: </span><span class="yaml-value">${clientConfig.value["authFlow"]}</span>
        <span class="yaml-key">realm<span class="yaml-colon">: </span></span><span class="yaml-value">${clientConfig.value["realm"]}</span>
        <span class="yaml-key">client-id<span class="yaml-colon">: </span></span><span class="yaml-value">${clientConfig.value["clientId"]}</span>`;

                clientConfigHtml += `
        <span class="yaml-key">idp-issuer-url<span class="yaml-colon">: </span></span><span class="yaml-value">${clientConfig.value["idpIssuerUrl"]}</span>`;

                if (clientConfig.value["redirectUri"]) {
                    clientConfigHtml += `
        <span class="yaml-key">redirect-uri</span><span class="yaml-colon">: </span><span class="yaml-value">${clientConfig.value["redirectUri"]}</span>`;
                }

                clientConfigHtml += `
      <span class="yaml-key">name</span><span class="yaml-colon">: </span><span class="yaml-value">oidc</span></pre>`;
                return clientConfigHtml;
            },
        };
    },
};
