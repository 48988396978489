
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        modelValue: {},
    },
    setup(props, ctx) {
        return {
            get model() {
                return props.modelValue;
            },
            set model(v) {
                ctx.emit("update:modelValue", v);
            },
        };
    },
});
