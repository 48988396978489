import { AttributeNameMapping } from "@/components/settings/custom-settings/SsoConfigClient";
import apiClient from "./api-client";

interface SamlConfig {
    entityId: string;
    redirectUri: string;
}
interface OidcConfig {
    redirectUri: string;
}

class SsoConfigService {
    configureSamlSso(samlConfig: any): Promise<SamlConfig> {
        return apiClient.asyncRequestWithResponse(`v1/k8s/auth/config-sso/saml`, "POST", samlConfig);
    }

    configureOAuthSso(oauthConfig: any): Promise<OidcConfig> {
        return apiClient.asyncRequestWithResponse(`v1/k8s/auth/config-sso/oauth`, "POST", oauthConfig);
    }

    getSsoMappings(identityProvider: string): Promise<Record<string, string>> {
        return apiClient.asyncRequestWithResponse(`v1/k8s/auth/sso/idps/${identityProvider}/mappers`, "GET");
    }
    
    updateSsoMapping(identityProvider: string, mappingConfig: AttributeNameMapping) {
        return apiClient.request(`v1/k8s/auth/sso/idps/${identityProvider}/mappers`, "PUT", mappingConfig);
    }
}

const ssoConfigService = new SsoConfigService();
export default ssoConfigService;
