

import { defineComponent, PropType } from 'vue';

  export default defineComponent({
    name: 'SsoProtocolSelector',
    emits: ["change-protocol"],
    props: {
        disabled: Boolean,
        selectedProtocolName: String,
        protocolMap: Object as PropType<Map<string, string>>,
    },
    setup(props, ctx) {
        function changeProtocol(protocolName: string) {
                ctx.emit("change-protocol", protocolName);
        }
        function isChecked(value: string): boolean {
            return value === props.selectedProtocolName;
        }
        return {
            changeProtocol,
            isChecked
        };
    },
  })
  