import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cef56e3e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "context"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Settings = _resolveComponent("Settings")!

  return (_openBlock(), _createBlock(_component_Settings, _mergeProps({ setting: _ctx.setting }, _ctx.$attrs), {
    default: _withCtx(() => [
      (_ctx.$attrs.modelValue)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getSettings(), (subSetting) => {
              return (_openBlock(), _createBlock(_resolveDynamicComponent("Setting"), {
                key: _ctx.setting.key,
                setting: subSetting,
                modelValue: _ctx.getValue(subSetting.key),
                "onUpdate:modelValue": ($event: any) => (_ctx.save(subSetting.key, $event))
              }, null, 8, ["setting", "modelValue", "onUpdate:modelValue"]))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 16, ["setting"]))
}