
import Settings from "../Setting.vue";
import tenantStore from "@/stores/tenantStore";
import Code from "@/core-ui/Code.vue";
import Button from "@/core-ui/Button.vue";
import { useFormCtrl } from "@/core-ui/forms/compositions";
import BoxInput from "@/core-ui/forms/components/BoxInput.vue";
import ssoService from "../../../services/sso-service";
import Tooltip from "@/core-ui/forms/components/Tooltip.vue";
import { onMounted, onUpdated, reactive, ref } from "vue";
import { MapperObject } from "@/types/settings";
import SsoProtocolSelector from './SsoProtocolSelector.vue'
import { ssoClient, AttributeNameMapping, defaultMapping, Protocol } from "@/components/settings/custom-settings/SsoConfigClient";
import { ssoLogic } from "@/components/settings/custom-settings/SsoLogicUtils";

export default {
    props: {
        setting: Object,
    },
    components: { Tooltip, Settings, Code, Button, BoxInput, SsoProtocolSelector },
    setup() {
        const protocolsMap = ssoLogic.generateProtocolsMap();
        let ssoAttributesMapping: AttributeNameMapping = {};
        const selectedProtocol = getIdentityProvidorFromUri();
        let selectedProtocolName = ref(ssoLogic.getSelectedProtocolName(selectedProtocol, protocolsMap));
        let ssoAttributesMappingsArray = reactive({items:[] as MapperObject[]});
        
        onMounted(async () => {
            const isSsoEnabled = tenantStore.state.ssoEnabled && selectedProtocol;
            ssoAttributesMapping = isSsoEnabled ? await ssoService.getSsoMappings(selectedProtocol) : defaultMapping;
            ssoAttributesMappingsArray.items = ssoLogic.getSsoMappingArrayForDisplay(ssoAttributesMapping);
        });

        onUpdated(() => {
            if (tenantStore.state.ssoEnabled === false) {
                ssoAttributesMapping = defaultMapping;
                ssoAttributesMappingsArray.items = ssoLogic.getSsoMappingArrayForDisplay(ssoAttributesMapping);
            }
        });

        function changeProtocol(protocolName: string) {
            selectedProtocolName.value = (Protocol[protocolName as keyof typeof Protocol]) as string;
        }

        function getIdentityProvidorFromUri(): string | undefined {
            let selectedProtocol;
            const redirectUri = tenantStore.state.ssoRedirectUri;
            if (redirectUri) {
                selectedProtocol = (Object.keys(Protocol) as Array<keyof typeof Protocol>).find(protocol => {
                    return redirectUri.includes(protocol)
                });
            }
            return selectedProtocol;
        }

        return {
            Protocol,
            protocolsMap: protocolsMap,
            selectedProtocolName,
            tenantStore,
            selectedProtocol,
            ssoAttributesMapping,
            ssoAttributesMappingsArray,
            changeProtocol,
            ssoFormCtrl: useFormCtrl({
                key: "config-saml",
                action: async (v: any) => {
                    const newMappings = ssoLogic.setNewAttributeMapping(ssoAttributesMapping, Object.assign({}, v));
                    ssoAttributesMappingsArray.items = ssoLogic.getSsoMappingArrayForDisplay(newMappings);
                    switch (selectedProtocolName.value) {
                        case Protocol.saml:
                            return ssoClient.configureSaml(v, newMappings);
                        case Protocol.oidc:
                            return ssoClient.configureOidc(v, newMappings);
                    } 
                },
            }),
            attributeFormCtrl: useFormCtrl({
                key: "config-attributes",
                action: async (v: any) => {
                    const newMappings = ssoLogic.setNewAttributeMapping(ssoAttributesMapping, Object.assign({}, v));
                    ssoAttributesMappingsArray.items = ssoLogic.getSsoMappingArrayForDisplay(newMappings);
                    const selectedProtocol = getIdentityProvidorFromUri();
                    if (selectedProtocol) {
                        return ssoClient.updateAttributeMapping(selectedProtocol, newMappings);
                    }
                },
            }),
        };
    },
};

