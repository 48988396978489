import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4387367e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "wandb-configuration"
}
const _hoisted_2 = { class: "form-row" }
const _hoisted_3 = { class: "label-box" }
const _hoisted_4 = { for: "host" }
const _hoisted_5 = { class: "input-box" }
const _hoisted_6 = {
  key: 0,
  class: "error"
}
const _hoisted_7 = { class: "actions-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_Input = _resolveComponent("Input")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Settings = _resolveComponent("Settings")!

  return (_openBlock(), _createBlock(_component_Settings, _mergeProps({ setting: $props.setting }, _ctx.$attrs), {
    default: _withCtx(() => [
      (_ctx.$attrs.modelValue)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("form", null, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("label", _hoisted_4, _toDisplayString($setup.sweepConfig?.label), 1),
                  _createVNode(_component_Tooltip, {
                    msg: $setup.sweepConfig?.description
                  }, null, 8, ["msg"])
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_Input, {
                    type: "text",
                    class: "input-host",
                    name: "host",
                    modelValue: $setup.state.host,
                    "onUpdate:modelValue": [
                      _cache[0] || (_cache[0] = ($event: any) => (($setup.state.host) = $event)),
                      $setup.handleInput
                    ],
                    label: "host",
                    invalid: !$setup.isValid
                  }, null, 8, ["modelValue", "invalid", "onUpdate:modelValue"]),
                  (!$setup.isValid)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_6, "Please provide a valid URL"))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_Button, {
                  class: "save-btn",
                  size: "sm",
                  text: "Save",
                  "button-type": "main",
                  type: "submit",
                  onClick: _withModifiers($setup.submitHandler, ["stop"])
                }, null, 8, ["onClick"])
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 16, ["setting"]))
}