
import settingsStore, { SettingType, SettingKeys } from "@/stores/setting-store";
import Setting from "@/components/settings/Setting.vue";

import { defineComponent, ref } from "vue";

import ProgressBar from "@/core-ui/ProgressBar.vue";
import Loading from "@/core-ui/Loading.vue";
import { useToast } from "vue-toastification";

export default defineComponent({
    components: {
        Setting,
        ProgressBar,
        Loading,
    },
    props: { customComponents: { type: Object } },

    setup(props) {
        settingsStore.setup();

        const selectedCategory = ref(null as any);
        const tempValues = ref({} as any);
        const toast = useToast();

        const self = {
            selectedCategory,
            getCategorySettings(): Array<SettingType> {
                return settingsStore.state.settings
                    .filter((setting: SettingType) => setting.active)
                    .map((setting: SettingType) => {
                        // temp solution by the product:
                        // if toggle is on for researcher auth, we want to prevent the user to turn it off
                        if (setting.key === SettingKeys.ProjectPermissionUse) {
                            setting.disabled = !!setting.value;
                        }

                        return setting;
                    });
            },

            get categories(): { [key: string]: typeof Setting } {
                return settingsStore.categories as any;
            },

            get categoriesDetails() {
                return Object.keys(self.categories);
            },

            get isInitialize() {
                return settingsStore.fetcher.state == "initialize";
            },

            get isUpdating() {
                return settingsStore.fetcher.state == "update";
            },

            getValue(key: string) {
                return tempValues.value[key] ?? settingsStore.state.kv[key];
            },

            getSettingComponent(settings: any) {
                return props.customComponents![settings.key] || Setting;
            },

            isLoading(key: string) {
                return key in tempValues.value;
            },

            async save(key: string, value: any) {
                tempValues.value[key] = value;
                if (await settingsStore.save(key, value, toast)) {
                    settingsStore.state.kv[key] = tempValues.value[key];
                }
                delete tempValues.value[key];
            },

            get selected() {
                return selectedCategory.value || Object.keys(self.categories)[0];
            },
        };
        return self;
    },
});
