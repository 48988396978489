
import Settings from "@/components/settings/Settings.vue";
import { SettingKeys } from "@/stores/setting-store";
import ResearcherAuthentication from "@/components/settings/custom-settings/ResearcherAuthentication.vue";
import LoginWithSso from "@/components/settings/custom-settings/LoginWithSso.vue";
import Page from "@/core-ui/Page.vue";
import EnableInactivityLogout from "@/components/settings/custom-settings/EnableInactivityLogout.vue";
import RegistryIntegration from "@/components/settings/custom-settings/RegistryIntegration.vue";
import WandbConfiguration from "@/components/settings/custom-settings/WandbConfiguration.vue";

export default {
    components: { Settings, Page },
    setup: () => ({
        customComponents: {
            [SettingKeys.ProjectPermissionUse]: ResearcherAuthentication,
            [SettingKeys.LoginWithSSO]: LoginWithSso,
            [SettingKeys.RegistryIntegration]: RegistryIntegration,
            [SettingKeys.EnableInactivityLogout]: EnableInactivityLogout,
            [SettingKeys.RegistryIntegration]: RegistryIntegration,
            [SettingKeys.EnableWandbSweeps]: WandbConfiguration,
        },
    }),
};
