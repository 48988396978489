import Input from "@/core-ui/TextInput.vue";
import Toggle from "@/core-ui/Toggle.vue";

const inputMap = {
    String: { comp: Input, props: { type: "text" } },
    Number: { comp: Input, props: { type: "number" } },
    Boolean: { comp: Toggle, props: {} },
};

export function getComponent(type: string) {
    return (inputMap as any)[type] || { Comp: Input, props: { type: "text" } };
}
