import ssoService from "@/services/sso-service";
import tenantStore from "@/stores/tenantStore";
import { useToast } from "vue-toastification";

export enum Protocol {
    saml = "Saml 2",
    oidc = "Open ID Connect",
}

export const defaultMapping: Record<string, string> = {
    "GID Mapper": "GID",
    "Groups Mapper": "GROUPS",
    "SupplementaryGroups Mapper": "SUPPLEMENTARYGROUPS",
    "UID Mapper": "UID",
}

export type AttributeNameMapping = Record<string, string | undefined>;

class SsoConfigClient {
    toast = useToast();
    
    async configureSaml(values: any, mappings: AttributeNameMapping) { 
        let body = this.generateSamlConfigRequestBody(values, mappings);
        try {
            const samlConfig = await ssoService.configureSamlSso(body);
            if (samlConfig) {
                tenantStore.state.ssoEntityId = samlConfig.entityId;
                tenantStore.state.ssoRedirectUri = samlConfig.redirectUri;
                tenantStore.state.ssoEnabled = true;
                this.toast?.success(`Saml IDP Configured`);
            } else {
                return this.handelSsoError("SAML", false);
            }
        } catch (ex) {
            return this.handelSsoError("SAML", true);
        }
    }

    async configureOidc(values: any, mappings: AttributeNameMapping) { 
        let body = this.generateOIDCConfIgRequestBody(values, mappings);
        try {
            const oidcConfig = await ssoService.configureOAuthSso(body);
            if (oidcConfig) {
                tenantStore.state.ssoRedirectUri = oidcConfig.redirectUri;
                tenantStore.state.ssoEnabled = true;
                this.toast?.success(`Oidc IDP Configured`);
            } else {
                return this.handelSsoError("OIDC", false);
            }
        } catch (ex) {
            return this.handelSsoError("OIDC", true);
        }
    }

    updateAttributeMapping(identityProvider: string, mappings: AttributeNameMapping) {
        try {
            ssoService.updateSsoMapping(identityProvider, mappings);
            this.toast?.success(`Attributes mapping configured successfully`);
        } catch {
            this.toast?.error(`Couldn't update ${identityProvider} mappings`);
            return Promise.reject(`Couldn't update sso mappings`);
        }  
    }

    private generateSamlConfigRequestBody(values: any, mapping: AttributeNameMapping) {
        return {
                metadataXmlUrl: values.metadataXmlUrl,
                adminUser: values.adminUser,
                mappers: mapping,
            };
    }
    
    private generateOIDCConfIgRequestBody(values: any, mapping: AttributeNameMapping) {
        return {
                adminUser: values.adminUser,
                wellKnownOidcConfigUrl: values.discoveryDocumentUrl,
                clientId: values.clientId,
                clientSecret: values.clientSecret,
                mappers: mapping,
            }
    } 

    private handelSsoError(identityProvider: string, isServerException: boolean) {
        const errorMessage = isServerException ? `${identityProvider} URL is not valid` : `Error with configuring ${identityProvider}`
        tenantStore.state.ssoEnabled = false;
        this.toast?.error(errorMessage);
        return Promise.reject(errorMessage);
    }
}

export const ssoClient = new SsoConfigClient();


