import { MapperObject } from "@/types/settings";
import { AttributeNameMapping, defaultMapping, Protocol } from "./SsoConfigClient";

class SsoLogicUtils {

    getSelectedProtocolName(selectedProtocol: string | undefined, protocolsMap: Map<string, string> ): string {
        return (selectedProtocol && protocolsMap.has(selectedProtocol)) ? protocolsMap.get(selectedProtocol)! : Protocol.saml;
    }

    generateProtocolsMap(): Map<string, string> {
        let protocolsMap = new Map();
        (Object.keys(Protocol) as Array<keyof typeof Protocol>).forEach(key => {
            protocolsMap.set(key, Protocol[key]);
        })
        return protocolsMap;
    }

    setNewAttributeMapping(ssoAttributesMapping: AttributeNameMapping, newValues: any): AttributeNameMapping {
        return Object.entries(ssoAttributesMapping).reduce((newObj, [key, value]) => {
                    if (value) {
                        newObj[key] = newValues[value];
                        if (newObj[key] === "") {
                            newObj[key] = defaultMapping[key];
                        }
                    }
                    return newObj;
                }, {} as AttributeNameMapping)
    }

    getSsoMappingArrayForDisplay(ssoAttributesMapping: AttributeNameMapping): MapperObject[] {
        const ssoMappingArray: MapperObject[] = [];
        if (ssoAttributesMapping) {
            Object.entries(ssoAttributesMapping).forEach(([key, name]) => {
                const value = name || defaultMapping[key];
                ssoMappingArray.push({
                    key,
                    label: defaultMapping[key],
                    aid: `sso-${value}`,
                    value,
                });
            });
        }
        return ssoMappingArray
    }
    
}

export const ssoLogic = new SsoLogicUtils();


