
import Code from "@/core-ui/Code.vue";
import Settings from "../Setting.vue";
import tenantStore from "@/stores/tenantStore";
import ClientConfig from "./ResearcherAuthenticationClientConfig.vue";
import ServerConfig from "./ResearcherAuthenticationServerConfig.vue";
import { ref, onMounted } from "vue";

export default {
    props: {
        setting: Object,
    },
    components: { Code, Settings, ClientConfig, ServerConfig },

    setup: () => {
        const clientConfigInfo = ref({});
        const serverConfigInfo = ref({});
        const realm = ref("");
        onMounted(async () => {
            const tenantConfigInfo = await tenantStore.getTenantConfigInfo();
            clientConfigInfo.value = tenantConfigInfo.clientConfigInfo;
            serverConfigInfo.value = tenantConfigInfo.serverConfigInfo;
            realm.value = tenantConfigInfo.clientConfigInfo.realm;
        });
        return {
            clientConfigInfo,
            serverConfigInfo,
            realm,
        };
    },
};
