<template>
    <i
        class="raicon-info v-tooltip__content"
        style="margin: 2px 8px"
        ref="tooltip"
    />
</template>

<script>
import { useTippy, useSingleton } from "vue-tippy";
import { ref} from "vue";

export default {
    props: {
        msg: String,
        link: String,
        linkText: String,
    },

    setup(props) {
        const tooltip = ref("");
        const { tippy: t1 } = useTippy(tooltip, {
            content: props.link ? `<div> ${props.msg} <a target="_blank" href=${props.link}>${props.linkText ? props.linkText : "here" }</a></div>` : `<div> ${props.msg}</div>`,
        });

        useSingleton([t1], {
            allowHTML: true,
            interactive: true,
        });

        return {
            tooltip,
        };
    },
}

</script>
<style scoped>
.v-tooltip__content {
    pointer-events: auto;
}
</style>