
import { getComponent } from "@/components/settings/InputMap";
import debounce from "lodash/debounce";
import { defineComponent, computed } from "vue";
import Tooltip from "@/core-ui/forms/components/Tooltip.vue";
export default defineComponent({
    props: {
        setting: Object,
        modelValue: {},
    },
    emits: ["update:modelValue"],
    components: { Tooltip },
    setup(props, ctx) {
        const { comp, props: compProps } = getComponent(props.setting!.type) as any;

        const valueCompProps = computed(() => {
            return { ...compProps, disabled: props.setting?.disabled };
        });

        const isInActivitySetting = computed(() => {
            return props.setting?.key === "tenant.inactivity_minutes_till_logout";
        });

        const updateStringValue = debounce((value) => {
            ctx.emit("update:modelValue", value);
        }, 2000);

        return {
            isInActivitySetting,
            valueComp: comp,
            valueCompProps,
            get model() {
                return props.modelValue;
            },

            set model(value) {
                props.setting!.type === "String" ? updateStringValue(value) : ctx.emit("update:modelValue", value);
            },
        };
    },
});
